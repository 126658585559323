<template>
  <!--begin::Content-->
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Liste des Services</h3>
            </div>
          </div>
          <div class="card-body">
            <!--begin: Datatable-->
            <table
              class="table table-bordered <!--table-separate--> table-head-custom table-checkable"
              id="kt_datatable_2"
              width="100%"
            >
              <thead>
                <tr>
                  <th>id</th>
                  <th>Demandeur</th>
                  <th>Description</th>
                  <th>Etats</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(request, index) in requests" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ request.nom }} {{ request.prenoms }}</td>
                  <td>{{ request.description | truncate }}</td>
                  <td v-if="request.etat === 0">
                    <span class="badge badge-dark">{{ etats["0"] }}</span>
                  </td>
                  <td v-if="request.etat === 1">
                    <span class="badge badge-primary">{{ etats["1"] }}</span>
                  </td>
                  <td v-if="request.etat === 2">
                    <span class="badge badge-danger">{{ etats["2"] }}</span>
                  </td>
                  <td v-if="request.etat === 3">
                    <span class="badge badge-success">{{ etats["3"] }}</span>
                  </td>
                  <td
                    class="btn-group"
                    v-if="request.etat === 0 || request.etat === 1"
                  >
                    <button
                      class="btn btn-info btn-sm"
                      @click="tryRequest(request)"
                      v-if="request.etat === 0"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="rejectRequest(request)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <button
                      class="btn btn-success btn-sm"
                      @click="acceptRequest(request)"
                      v-if="request.etat !== 0"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </td>
                  <td v-else-if="request.etat === 2">
                    <button
                      class="btn btn-info btn-sm"
                      @click="infoRejectRequest(request)"
                    >
                      <i class="fas fa-eye"></i> Voir détails
                    </button>
                  </td>
                  <td v-else>
                    <span class="badge badge-light"
                      ><i class="fas fa-check-double text-success"></i> Demande
                      accepter</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <!--end: Datatable-->
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  name: "List",
  data() {
    return {
      create: "ad/create",
      source: "demande",
      resource: "demande",
      requests: [],
      etats: {
        0: "En Attente...",
        1: "En traitement",
        2: "Rejeter",
        3: "Accepter"
      },
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "name",
          label: "Désignation"
        },
        {
          key: "type_tag",
          label: "Type de tag"
        },
        {
          key: "created_at",
          label: "Date Ajout",
          formatter: value => {
            return moment(String(value)).format("DD-MM-YYYY");
          }
        },
        "Actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.requests = response.data.diasporaRequests;
      });
    },
    rejectRequest(request) {
      this.reject(`demande/${request.id}/reject`, "demande");
    },
    infoRejectRequest(request) {
      this.info(request.motif);
    },
    acceptRequest(request) {
      this.accept(`demande/${request.id}/accept`, "Demande");
    },
    tryRequest(request) {
      Swal.fire({
        title: "Confirmation!",
        text: "Voulez vous vraiment traiter cette demande ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuller",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          ApiService.put(`demande/${request.id}/trying`)
            .then(response => {
              Swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              this.fetchData();
            })
            .catch(error => {
              Swal.fire({
                title: "Erreur",
                text: error.message,
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            });
        } else {
          Swal.fire({
            title: "Erreur",
            text: "Votre demande de traitement n'a pas été éffectuée !",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    },
    reject(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment rejeter cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Motif",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          Swal.fire({
            input: "textarea",
            inputLabel: "Message",
            inputPlaceholder: "Saisir le message...",
            inputAttributes: {
              "aria-label": "Saisir le message"
            },
            inputValidator: value => {
              if (!value) {
                return "Veillez saisir un motif svp!";
              }
            },
            showCancelButton: true
          }).then(isConfirm => {
            if (isConfirm.isDismissed) {
              Swal.fire({
                title: "Erreur",
                text: "Votre motif n'a été enregistré !",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
            if (isConfirm.isConfirmed) {
              let response = ApiService.put(url, { motif: isConfirm.value });
              if (response) {
                Swal.fire({
                  title: "Success",
                  text: "Votre motif a été enregistrer avec success !",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp"
                  }
                });
                this.fetchData();
              }
            }
          });
        }
      });
    },
    info(data) {
      Swal.fire({
        title: "Motif du rejet",
        text: data,
        icon: "info",
        confirmButtonText: "Ok"
      });
    },
    accept(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment accepter cette ${name} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accepter",
        cancelButtonText: "Annuller",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          let response = ApiService.get(url);
          if (response) {
            Swal.fire({
              title: `${name} accepté!`,
              text: `${name} a bien été acceptée avec success`,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            this.fetchData();
          }
        } else {
          Swal.fire({
            title: "Erreur",
            text: "l'action n'a pas été effectuée !",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
